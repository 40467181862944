/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import plonevoltoSlate from '@plone/volto-slate';
import voltoApp from 'volto-app';
import voltoRahSite from 'volto-rah-site';
import voltoLeafletBlock from 'volto-leaflet-block';
import voltoJitsiBlock from 'volto-jitsi-block';
import voltoMapLibreBlock from 'volto-MapLibre-block';
import voltoRevealBlock from 'volto-reveal-block';
import voltoRaTheme from 'volto-ra-theme';

const addonsInfo = [{"modulePath":"/app/node_modules/@plone/volto/packages/volto-slate/src","packageJson":"/app/node_modules/@plone/volto/packages/volto-slate/package.json","version":"17.18.1","isPublishedPackage":false,"isRegisteredAddon":false,"name":"@plone/volto-slate","addons":[]},{"name":"volto-app","version":"0.1.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/app/src/addons/volto-app/src","packageJson":"/app/src/addons/volto-app/package.json","addons":[]},{"modulePath":"/app/src/addons/volto-rah-site/src","packageJson":"/app/src/addons/volto-rah-site/package.json","version":"0.1.0","isPublishedPackage":false,"isRegisteredAddon":true,"name":"volto-rah-site","addons":[]},{"name":"volto-leaflet-block","version":"0.2.5","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/app/node_modules/volto-leaflet-block/src","packageJson":"/app/node_modules/volto-leaflet-block/package.json","addons":[]},{"name":"volto-jitsi-block","version":"0.1.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/app/node_modules/volto-jitsi-block/src","packageJson":"/app/node_modules/volto-jitsi-block/package.json","addons":[]},{"name":"volto-MapLibre-block","version":"0.3.6","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/app/node_modules/volto-MapLibre-block/src","packageJson":"/app/node_modules/volto-MapLibre-block/package.json","addons":[]},{"name":"volto-reveal-block","version":"0.3.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/app/node_modules/volto-reveal-block/src","packageJson":"/app/node_modules/volto-reveal-block/package.json","addons":[]},{"name":"volto-ra-theme","version":"0.1.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/app/node_modules/volto-ra-theme/src","packageJson":"/app/node_modules/volto-ra-theme/package.json","addons":[]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [plonevoltoSlate, voltoApp, voltoRahSite, voltoLeafletBlock, voltoJitsiBlock, voltoMapLibreBlock, voltoRevealBlock, voltoRaTheme];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
